<template>
<!--- 
 v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'white'"
--->
  <v-app-bar
    fixed
    flat
    app
    class="mb-2"
  >
  <v-row justify="space-between" align="center" no-gutters>
    <v-col class="pa-0" cols="6">
      <v-slide-x-transition>
      <!---  v-if="showLogo" --->
      <a href="https://www.slenderiser.de">
      <v-img       
        :src="require('@/assets/slenlogo.png')"
        class="shrink"
        contain
        height="55"
        position="left"
      /> </a>
    </v-slide-x-transition>
    </v-col>
     <v-col class="pa-0" cols="6">
<v-card class="text-end" color="transparent" flat>
      <social-media/>
    </v-card>
    </v-col>
  </v-row>
    
    
  </v-app-bar>
</template>

<script>
  export default {
    name: 'CoreAppBar',
    components: {
      SocialMedia: () => import('@/components/SocialMedia'),
    },

    data: () => ({
      showLogo: false,
      isScrolling: false,
    }),

    methods: {
      onScroll () {
        const offset = window.pageYOffset
        this.isScrolling = offset > 50
        this.showLogo = offset > 200
      },
    },
  }
</script>